import React from "react";
import { BackArrowPrimaryOutline } from "../../atoms/back-arrow-primary-outline/back-arrow-primary-outline";

export const GoBackButton: React.FC = () => {
  return (
    <div className="flex flex-row gap-x-[9px] items-center">
      <BackArrowPrimaryOutline />
      <div className="text-primary text-[14px] leading-[18px] font-semibold flex items-center">Back</div>
    </div>
  );
};
