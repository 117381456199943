import React from "react";
import { FormLabel } from "../../atoms/form-label/form-label";
import { Input } from "../../atoms/input/input";

export const LoginForm: React.FC = () => {
  return (
    <div className="w-[372px]">
      <FormLabel label="Username" />
      <Input onChange={() => {}} placeholder="Username" type="text" value="" />
      <FormLabel label="Password" />
      <Input onChange={() => {}} placeholder="Password" type="text" value="" />
      <div className="flex items-center gap-x-[30px]">
        <button className="login-button px-[31px] py-[11px]">Login</button>
        <p className="cursor-pointer text-[11px] leading-[15px] font-normal text-gray3">Forgot Username/Password?</p>
      </div>
    </div>
  );
};
