import { gql } from "@apollo/client";

export const INSERT_INGREDIENT = gql`
  mutation insert_ingredients($objects: [ingredients_insert_input!]!) {
    insert_ingredients(objects: $objects) {
      affected_rows
    }
  }
`;

export const UPDATE_INGREDIENT = gql`
  mutation update_ingredients($where: ingredients_bool_exp!, $_set: ingredients_set_input) {
    update_ingredients(where: $where, _set: $_set) {
      affected_rows
    }
  }
`;

export const DELETE_INGREDIENT_ALLERGIES = gql`
  mutation delete_ingredient_allergies($where: ingredient_allergies_bool_exp!) {
    delete_ingredient_allergies(where: $where) {
      affected_rows
    }
  }
`;

export const INSERT_INGREDIENT_ALLERGIES = gql`
  mutation insert_ingredient_allergies($objects: [ingredient_allergies_insert_input!]!) {
    insert_ingredient_allergies(objects: $objects) {
      affected_rows
    }
  }
`;

export const DELETE_INGREDIENT_BY_ID = gql`
  mutation delete_ingredients($where: ingredients_bool_exp!) {
    delete_ingredients(where: $where) {
      affected_rows
    }
  }
`;

export const ADD_NEW_MEAL = gql`
  mutation insert_meal($objects: [meal_insert_input!]!) {
    insert_meal(objects: $objects) {
      returning {
        id
      }
    }
  }
`;

export const DELETE_MEAL_BY_ID = gql`
  mutation delete_meal($where: meal_bool_exp!) {
    delete_meal(where: $where) {
      affected_rows
    }
  }
`;

export const UPDATE_MEAL_BY_ID = gql`
  mutation update_meal($where: meal_bool_exp!, $_set: meal_set_input) {
    update_meal(where: $where, _set: $_set) {
      affected_rows
    }
  }
`;

export const DELETE_PROCEDURES = gql`
  mutation delete_instructions($where: instructions_bool_exp!) {
    delete_instructions(where: $where) {
      affected_rows
    }
  }
`;

export const INSERT_PROCUDURES = gql`
  mutation insert_instructions($objects: [instructions_insert_input!]!) {
    insert_instructions(objects: $objects) {
      affected_rows
    }
  }
`;

export const DELETE_RECIPES = gql`
  mutation delete_recipe($where: recipe_bool_exp!) {
    delete_recipe(where: $where) {
      affected_rows
    }
  }
`;

export const INSERT_RECIPES = gql`
  mutation insert_recipe($objects: [recipe_insert_input!]!) {
    insert_recipe(objects: $objects) {
      affected_rows
    }
  }
`;

export const DELETE_ACCOMPANIMENTS = gql`
  mutation delete_submeal_meal($where: submeal_meal_bool_exp!) {
    delete_submeal_meal(where: $where) {
      affected_rows
    }
  }
`;

export const INSERT_ACCOMPANIMENTS = gql`
  mutation insert_submeal_meal($objects: [submeal_meal_insert_input!]!) {
    insert_submeal_meal(objects: $objects) {
      affected_rows
    }
  }
`;

export const DELETE_TAGS = gql`
  mutation delete_meal_filters($where: meal_filters_bool_exp!) {
    delete_meal_filters(where: $where) {
      affected_rows
    }
  }
`;

export const INSERT_TAGS = gql`
  mutation insert_meal_filters($objects: [meal_filters_insert_input!]!) {
    insert_meal_filters(objects: $objects) {
      affected_rows
    }
  }
`;

export const DELETE_SHELF_TIME = gql`
  mutation delete_shelf_time($where: shelf_time_bool_exp!) {
    delete_shelf_time(where: $where) {
      affected_rows
    }
  }
`;

export const INSERT_SHELF_TIME = gql`
  mutation insert_shelf_time($objects: [shelf_time_insert_input!]!) {
    insert_shelf_time(objects: $objects) {
      returning {
        id
      }
    }
  }
`;

export const DELETE_SCALING_UNIT = gql`
  mutation delete_add_on_pax($where: add_on_pax_bool_exp!) {
    delete_add_on_pax(where: $where) {
      affected_rows
    }
  }
`;

export const DELETE_MEALS_MUTATIONS = gql`
  mutation delete_meals_mutations(
    $instructions: instructions_bool_exp!
    $recipe: recipe_bool_exp!
    $submeal_meal: submeal_meal_bool_exp!
    $mealFilters: meal_filters_bool_exp!
    $shelfTime: shelf_time_bool_exp!
    $addOnPax: add_on_pax_bool_exp!
  ) {
    delete_instructions(where: $instructions) {
      affected_rows
    }

    delete_recipe(where: $recipe) {
      affected_rows
    }

    delete_submeal_meal(where: $submeal_meal) {
      affected_rows
    }

    delete_meal_filters(where: $mealFilters) {
      affected_rows
    }

    delete_shelf_time(where: $shelfTime) {
      affected_rows
    }

    delete_add_on_pax(where: $addOnPax) {
      affected_rows
    }
  }
`;

export const INSERT_MEALS_MUTATIONS = gql`
  mutation insert_meals_mutations(
    $instructions: [instructions_insert_input!]!
    $recipe: [recipe_insert_input!]!
    $submeal_meal: [submeal_meal_insert_input!]!
    $mealFilters: [meal_filters_insert_input!]!
    $shelfTime: [shelf_time_insert_input!]!
  ) {
    insert_instructions(objects: $instructions) {
      affected_rows
    }
    insert_recipe(objects: $recipe) {
      affected_rows
    }
    insert_submeal_meal(objects: $submeal_meal) {
      affected_rows
    }
    insert_meal_filters(objects: $mealFilters) {
      affected_rows
    }
    insert_shelf_time(objects: $shelfTime) {
      returning {
        id
      }
    }
  }
`;
