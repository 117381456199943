import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ListHeader } from "../../components/organisms/list-header/list-header";
import { IngredientsList } from "../../components/organisms/ingredients-list/ingredients-list";
import { PageTemplate } from "../../components/templates/page-template/page-template";
import { DELETE_INGREDIENT_BY_ID } from "../../graphql/mutations";
import { GET_ALL_INGREDIENTS, GET_ALL_INGREDIENTS_TOTAL, SEARCH_INGREDIENTS } from "../../graphql/queries";
import { Types_Ingredients } from "../../typescript/types";
import { Loader } from "../../components/molecules/loader/loader";

export const Ingredients: React.FC = () => {
  const history = useHistory();
  const [searchValue, setSearchValue] = useState("");
  const [ingredients, setIngredients] = useState<Types_Ingredients[]>([]);
  const [totalIngredients, setTotalIngredients] = useState<number>(0);
  const [offset, setOffset] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [justDeletedFlag, setJustDeletedFlag] = useState(false);
  const [justSearchedFlag, setJustSearchedFlag] = useState(false);

  const LIMIT = 30;
  const OFFSET = 30;

  const [getAllIngredients] = useLazyQuery(GET_ALL_INGREDIENTS, {
    variables: {
      limit: LIMIT,
      offset: 0,
    },
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const _ingredients = ingredients;
      const concatIngredients = _ingredients.concat(data.ingredients);
      setIngredients(concatIngredients);
      setLoading(false);
    },
  });

  const [searchForIngredients] = useLazyQuery(SEARCH_INGREDIENTS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const _ingredients = ingredients;
      const concatIngredients = _ingredients.concat(data.ingredients);
      setIngredients(concatIngredients);
      setLoading(false);
    },
  });

  useEffect(() => {
    setLoading(true);
    setIngredients([]);
    setOffset(0);
    getTotal();
    getAllIngredients({
      variables: {
        limit: LIMIT,
        offset: 0,
      },
    });
  }, []);

  const [getTotal] = useLazyQuery(GET_ALL_INGREDIENTS_TOTAL, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setTotalIngredients(data.ingredients_aggregate.aggregate.count);
    },
  });

  const [deleteIngredient] = useMutation(DELETE_INGREDIENT_BY_ID);

  const handleAddToList = () => history.push(`/create-new-ingredient`);

  const handleOnChangeSearch: (event: React.ChangeEvent<HTMLInputElement>) => void = (e) =>
    setSearchValue(e.target.value);

  const handleEdit: (id: number) => void = (id) => history.push(`/update-ingredient/${id}`);

  const pagination: () => void = () => {
    // flag so that the function does not update value of
    // offset when an ingredient is deleted.
    // set to True after 2 sec of deletion
    if (!justDeletedFlag) {
      const _offset = offset + OFFSET;
      setOffset(_offset);
      getAllIngredients({
        variables: {
          limit: LIMIT,
          offset: _offset,
        },
      });
    }
  };

  const handleDelete: (id: number) => void = (id) => {
    deleteIngredient({
      variables: {
        where: {
          id: {
            _eq: id,
          },
        },
      },
    }).then(() => {
      setJustDeletedFlag(true);
      setIngredients([]);
      setOffset(0);
      getAllIngredients({
        variables: {
          limit: LIMIT,
          offset: 0,
        },
      });
      getTotal();
      // flag so that the function does not update value of
      // offset when an ingredient is deleted.
      // set to True after 2 sec of deletion
      setTimeout(() => {
        setJustDeletedFlag(false);
      }, 2000);
    });
  };

  const search = () => {
    setOffset(0);
    setJustSearchedFlag(true);
    setIngredients([]);
    setLoading(true)
    searchForIngredients({
      variables: {
        where: {
          name: {
            _ilike: `%${searchValue.trim()}%`,
          },
        },
      },
    });
  };

  const onPressEnterInSearch: (event: React.KeyboardEvent<HTMLInputElement>) => void = (e) =>
    e.key === "Enter" && search();

  const onClickEmptySearchButton = () => {
    setIngredients([]);
    setJustSearchedFlag(false);
    setLoading(true);
    getTotal();
    getAllIngredients({
      variables: {
        limit: 30,
        offset: 0,
      },
    });
    setSearchValue("");
  };

  return (
    <PageTemplate>
      <ListHeader
        {...{ handleAddToList, handleOnChangeSearch, searchValue, onPressEnterInSearch, onClickEmptySearchButton }}
        pageHeading={`Ingredients (${totalIngredients})`}
      />
      {!loading ? (
        <IngredientsList
          data={ingredients}
          {...{ handleDelete, handleEdit, totalIngredients, pagination, justSearchedFlag }}
        />
      ) : (
        <Loader />
      )}
    </PageTemplate>
  );
};
