import { RouteConfigProps } from "../typescript/types";
import App from "../App"
import { CreateNewIngredient } from "../pages/create-new-ingredients/create-new-ingredient";
import { Ingredients } from "../pages/ingredients/ingredients";
import { Meals } from "../pages/meals/meals";
import { CreateNewMeal } from "../pages/create-new-meal/create-new-meal";
import { Home } from "../pages/home/home";
import { Settings } from "../pages/settings/settings";
import { Login } from "../pages/login/login";
export const routes: RouteConfigProps[] = [
  {
    path: '/',
    exact: true,
    component: Home,
    protected: false
  },
  {
    path: '/login',
    exact: true,
    component: Login,
    protected: false
  },
  {
    path: '/settings',
    exact: true,
    component: Settings,
    protected: false
  },
  {
    path: '/ingredients',
    exact: true,
    component: Ingredients,
    protected: false
  },
  {
    path: '/create-new-ingredient',
    exact: true,
    component: CreateNewIngredient,
    protected: false
  },
  {
    path: '/update-ingredient/:ingredientId',
    exact: false,
    component: CreateNewIngredient,
    protected: false
  },
  {
    path: '/meals',
    exact: true,
    component: Meals,
    protected: false
  },
  {
    path: '/create-new-meal',
    exact: true,
    component: CreateNewMeal,
    protected: false
  },
  {
    path: '/update-meal/:mealId',
    exact: false,
    component: CreateNewMeal,
    protected: false
  },
  
]