import { useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { PageHeading } from "../../components/atoms/page-heading/page-heading";
import { GoBackButton } from "../../components/molecules/go-back-button/go-back-button";
import { Loader } from "../../components/molecules/loader/loader";
import { AddIngredientForm } from "../../components/organisms/add-ingredient-form/add-ingredient-form";
import { PageTemplate } from "../../components/templates/page-template/page-template";
import { FileUploadUrl } from "../../config/config";
import { BASE_URL_S3_BUCKET_RESPONSE } from "../../constants";
import {
  DELETE_INGREDIENT_ALLERGIES,
  INSERT_INGREDIENT,
  INSERT_INGREDIENT_ALLERGIES,
  UPDATE_INGREDIENT,
} from "../../graphql/mutations";
import { GET_ALL_ALLERGIES } from "../../graphql/queries";
import {
  Types_Allergy,
  Type_IngredientInitialValues,
  Type_IngredientsInitialValue,
  Type_Notification,
  Type_SelectData,
} from "../../typescript/types";
import { replaceSpacesWithPlus } from "../../utils/replaceSpaceWithPlus";
import { Notification } from "../../components/molecules/notification/notification";
import { Enum_Notification } from "../../typescript/enum";

export const CreateNewIngredient: React.FC = () => {
  const history = useHistory();
  const [allergies, setAllergies] = useState<Type_SelectData[]>([]);
  const [selectedAllergies, setSelectedAllergies] = useState<Type_SelectData[]>([]);
  const [isUpdatePage, setIsUpdatePage] = useState(false);
  const [ingredientId, setIngredientId] = useState(0);
  const [allergiesToDelete, setAllergiesToDelete] = useState<number[]>([]);
  const [imageLabel, setImageLabel] = useState("");
  const [loading, setLoading] = useState(true);
  const [ingredientName, setIngredientName] = useState("");
  const [formValues, setFormValues] = useState<Type_IngredientsInitialValue>({
    ingredientName: "",
    scalingUnit: 0,
  });
  const [imageUrl, setImageUrl] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const [notification, setNotification] = useState<Type_Notification>({
    heading: "",
    type: Enum_Notification.neutral,
    subHeading: "",
  });
  useQuery(GET_ALL_ALLERGIES, {
    onCompleted: (data) => {
      const _allergies = data.allergies.map((allergy: Types_Allergy) => {
        return {
          label: allergy.name,
          value: allergy.id,
        };
      });
      setAllergies(_allergies);
      setLoading(false);
    },
  });

  const [addIngredient] = useMutation(INSERT_INGREDIENT);
  const [updateIngredient] = useMutation(UPDATE_INGREDIENT);
  const [deleteAllergies] = useMutation(DELETE_INGREDIENT_ALLERGIES);
  const [insertAllergies] = useMutation(INSERT_INGREDIENT_ALLERGIES);

  const _addIngredient: () => void = () => {
    addIngredient({
      variables: {
        objects: {
          name: formValues.ingredientName,
          scaling_unit: formValues.scalingUnit,
          image_url: imageUrl,
          ingredient_allergies: {
            data: selectedAllergies.map((allergy) => {
              return {
                allergies_id: allergy.value,
              };
            }),
          },
        },
      },
    }).then(() => {
      setLoading(false);
      goToIngredientsPage();
    });
  };

  const _updateIngredients: (ingredientId: number) => void = async (ingredientId) => {
    try {
      await updateIngredient({
        variables: {
          where: {
            id: {
              _eq: ingredientId,
            },
          },
          _set: {
            name: formValues.ingredientName,
            scaling_unit: formValues.scalingUnit,
            image_url: imageUrl,
          },
        },
      });

      await deleteAllergies({
        variables: {
          where: {
            allergies_id: {
              _in: allergiesToDelete,
            },
            ingredients_id: {
              _eq: ingredientId,
            },
          },
        },
      });

      await insertAllergies({
        variables: {
          objects: selectedAllergies.map((allergy) => {
            return {
              allergies_id: allergy.value,
              ingredients_id: ingredientId,
            };
          }),
        },
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitIngredient: () => void = () => {
    setLoading(true);

    if (!isUpdatePage) {
      _addIngredient();
    } else if (isUpdatePage) {
      _updateIngredients(ingredientId);
    }
  };

  const handleImageUpload: (event: React.ChangeEvent<HTMLInputElement>) => void = async (e) => {
    setShowNotification(true);
    setNotification({
      heading: "File is uploading",
      type: Enum_Notification.warning,
    });
    setImageLabel("");
    setImageUrl("");

    try {
      if (e.target.files) {
        const file = e.target.files[0];
        let data = new FormData();
        data.append("files", file);
        const response = await fetch(FileUploadUrl, {
          method: "POST",
          body: data,
        });
        const _response = await response.json();
        setImageLabel(_response.filesUrl[0].originalname);
        const imageUrl = `${BASE_URL_S3_BUCKET_RESPONSE}/${replaceSpacesWithPlus(_response.filesUrl[0].originalname)}`;
        setImageUrl(imageUrl);
        setShowNotification(true);
        setNotification({
          heading: "File uploaded successfully",
          type: Enum_Notification.success,
        });
      }
    } catch (error) {
      console.log(error);
      setShowNotification(true);
      setNotification({
        heading: "File was not uploaded!",
        type: Enum_Notification.error,
      });
    } finally {
      setLoading(false);
    }
  };

  const goBack = () => history.goBack();

  const setIsUpdatePageToTrue: () => void = () => setIsUpdatePage(true);

  const _setIngredientId: (id: number) => void = (id) => setIngredientId(id);

  const goToIngredientsPage: () => void = () => history.push(`/ingredients`);

  return (
    <PageTemplate>
      <div onClick={() => goBack()}>
        <GoBackButton />
      </div>
      <PageHeading heading={isUpdatePage ? "Update Ingredient" : "Create New Ingredient"} />
      {!loading ? (
        <AddIngredientForm
          {...{
            allergies,
            handleSubmitIngredient,
            selectedAllergies,
            setSelectedAllergies,
            goBack,
            setIsUpdatePageToTrue,
            isUpdatePage,
            _setIngredientId,
            setAllergiesToDelete,
            handleImageUpload,
            imageLabel,
            imageUrl,
            setImageUrl,
            loading,
            setLoading,
            setImageLabel,
            setShowNotification,
            setNotification,
            formValues,
            setFormValues,
          }}
        />
      ) : (
        <Loader />
      )}
      {showNotification && <Notification {...notification} showNotification={setShowNotification} />}
    </PageTemplate>
  );
};
