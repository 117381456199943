import { TODO } from "./typescript/types";

export const STYLES = {
  control: (styles: TODO, { isFocused, isSelected }: TODO) => ({
    ...styles,
    backgroundColor: "white",
    minHeight: 50,
    
  }),
  option: (styles: TODO, { data, isDisabled, isFocused, isSelected }: TODO) => {
    return {
      ...styles,
      "backgroundColor": isDisabled ? null : isSelected ? "rgb(230,230,230)" : isFocused ? "rgb(247,247,247)" : null,
      "color": isDisabled ? "#ccc" : isSelected && "black",
      "cursor": isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled && isSelected && "rgb(247,247,247)",
      },
    };
  },
  multiValue: (styles: TODO, { data }: TODO) => {
    return {
      ...styles,
      display: "none"
    };
  },
  multiValueLabel: (styles: TODO, { data }: TODO) => ({
    ...styles,
    color: data.color,
  }),
  multiValueRemove: (styles: TODO, { data }: TODO) => ({
    ...styles,
    color: data.color,
    ':hover': {
      backgroundColor: data.color,
      color: 'white',
    },
  }),
  input: (styles: TODO, { data, isDisabled, isFocused, isSelected }: TODO) => {
    return {
      ...styles,
    };
  },
  placeholder: (styles: TODO) => ({ ...styles }),
  singleValue: (styles: TODO, { data }: TODO) => ({ ...styles }),
};

export const BASE_URL_S3_BUCKET_RESPONSE = "https://menulyuploads.s3.amazonaws.com";


