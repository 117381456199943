import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { Sidebar } from "../components/organisms/sidebar/sidebar";

const AppRouter = ({ routes }: any) => {
  return (
    <Router>
      <Switch>
        {routes.map((route: any, index: any) => (
          <RouteWithSubRoutes key={index} {...route} />
        ))}
      </Switch>
    </Router>
  );
};

function RouteWithSubRoutes(route: any) {
  return (
    <>
      <Sidebar />
      <Route
        exact={route.exact}
        path={route.path}
        render={(props) => <route.component {...props} routes={route.routes} />}
      />
    </>
  );
}

export default AppRouter;
