import React, { useContext } from "react";
import { CloseButtonFilled } from "../../atoms/close-button-filled/close-button-filled";

export const SingleTag: React.FC<{ tagName: string, id: number, onDeleteTag: (id: number) => void
}> = ({ tagName, id, onDeleteTag }) => {
  return (
    <div className="flex border-primary border-[1px] rounded-full pl-[11px] pr-[6px] py-[6px]" style={{width: "max-content"}} onClick={()=> onDeleteTag(id)}>
      <p className="text-primary text-[10px] leading-[16px] font-semibold pr-[4px]">{tagName}</p>
      <CloseButtonFilled />
    </div>
  );
};