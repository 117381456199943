import React from 'react'
import { PageTemplate } from '../../components/templates/page-template/page-template'

export const Settings: React.FC = () => {
  return (
    <PageTemplate>
      Settings
    </PageTemplate>
  )
}
