import React from 'react'
import { useHistory } from 'react-router-dom'
import { OutlineButton } from '../../atoms/outline-button/outline-button'

type HomeCardProps = {
  heading: "Dishes" | "Ingredients";
  amount: number;
  image: string;
  isIngredients?: boolean
  viewAll: string
  addNew: string
}

export const HomeCard: React.FC<HomeCardProps> = ({amount,heading, image, isIngredients=false, viewAll, addNew}) => {
  const history = useHistory()
  return (
    <div className="relative bg-white overflow-hidden rounded-[20px] px-[48px] pb-[54px] w-[470px] h-[407px] drop-shadow">
      <img src={image} alt="" className={`absolute top-0 ${isIngredients ? "right-0" : "right-[30px]"}`} />
      <div className="absolute left-[48px] bottom-[54px] flex flex-col">
        <p className="text-[20px] font-semibold text-primary">{heading}</p>
        <p className="text-purple2 text-[90px] font-bold  leading-[110px] pb-[9px] ">{amount}</p>
        <div className="flex items-center gap-x-[11px]">
          <button className="submit-button" onClick={()=>history.push(viewAll)}>View All</button>
          <div onClick={()=>history.push(addNew)}>
            <OutlineButton label="Add New" />
          </div>
        </div>
      </div>
    </div>
  );
}
