import React from "react";
import Spinner from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

export const Loader: React.FC = () => {
  return (
    <div className="fixed top-[50%] left-[50%] ml-[-40px]">
      <Spinner type="TailSpin" height={80} width={80} color="#2F0842" />
    </div>
  );
};
