import React from "react";

type InputProps = {
  marginBottom?: boolean;
  type: "text" | "number";
  placeholder: string;
  value: number | string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const Input: React.FC<InputProps> = ({ marginBottom = true, type, placeholder, onChange, value }) => {
  return (
    <input
      placeholder={placeholder}
      type={type}
      className={`h-[50px] rounded-[4px] border border-[#b9b9b9] text-[14px] text-fontGray px-[15px] py-[20px] w-full ${
        marginBottom ? "mb-[30px]" : ""
      }`}
      onChange={onChange}
      value={value}
    />
  );
};
