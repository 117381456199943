import React from 'react'
import { SingleTag } from '../single-tag/single-tag'

type TagsListProps = {
  tags: any[]
  onDeleteTag: (id: number) => void

}

export const TagsList: React.FC<TagsListProps> = ({tags, onDeleteTag}) => {
  return (
    <div className="flex flex-wrap gap-[10px] w-[568px] mb-[17px]">
        {
          tags.map(tag => <SingleTag key={tag.value} tagName={tag.label} id={tag.value} onDeleteTag={onDeleteTag} />)
        }
        </div>
  )
}
