import React from "react";
import { AddToListIcon } from "../../atoms/add-to-list-icon/add-to-list-icon";
import { FiltersIcon } from "../../atoms/filters-icon/filters-icon";
import { PageHeading } from "../../atoms/page-heading/page-heading";
import { SearchInputWithIcon } from "../../molecules/search-input-with-icon/search-input-with-icon";

type ListHeaderProps = {
  pageHeading: string;
  handleAddToList: () => void;
  searchValue: string;
  handleOnChangeSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onPressEnterInSearch: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onClickEmptySearchButton: () => void
};

export const ListHeader: React.FC<ListHeaderProps> = ({
  handleAddToList,
  handleOnChangeSearch,
  pageHeading,
  searchValue,
  onPressEnterInSearch,onClickEmptySearchButton
}) => {
  return (
    <div className="flex justify-between items-center">
      <div className="flex items-center gap-x-[20px]">
        <PageHeading heading={pageHeading} />
        <div onClick={handleAddToList}>
          <AddToListIcon />
        </div>
      </div>
      <div className="flex items-center gap-x-[20px]">
        <FiltersIcon />
        <SearchInputWithIcon {...{ handleOnChangeSearch, searchValue, onPressEnterInSearch, onClickEmptySearchButton}} />
      </div>
    </div>
  );
};
