import React from "react";

type SearchInputProps = {
  onChangeSearchValue: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onPressEnter?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  searchValue: string;
  placeholder: string
};

export const SearchInput: React.FC<SearchInputProps> = ({ onChangeSearchValue, onPressEnter, searchValue, placeholder }) => {
  return (
    <input
      className="text-primary font-medium text-[13px] leading-[15px]  border rounded-[30px] h-[50px] pl-[51px] pr-[40px] placeholder-[#6f6f6f] outline-none focus:border-primary w-[269px]"
      placeholder={placeholder}
      onChange={(e) => onChangeSearchValue(e)}
      value={searchValue}
      onKeyPress={(e) => onPressEnter && onPressEnter(e)}
    />
  );
};
