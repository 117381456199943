import React from "react";
import { PageHeading } from "../../components/atoms/page-heading/page-heading";
import { LoginForm } from "../../components/organisms/login-form/login-form";
import { LoginTemplate } from "../../components/templates/login-template/login-template";

export const Login: React.FC = () => {
  return (
    <LoginTemplate>
      <PageHeading heading="Menuly Admin Login" />
      <LoginForm />
    </LoginTemplate>
  );
};
