import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { FileUploadUrl } from "../../../config/config";
import { BASE_URL_S3_BUCKET_RESPONSE, STYLES } from "../../../constants";
import {
  ADD_NEW_MEAL,
  DELETE_ACCOMPANIMENTS,
  DELETE_MEALS_MUTATIONS,
  DELETE_PROCEDURES,
  DELETE_RECIPES,
  DELETE_SCALING_UNIT,
  DELETE_SHELF_TIME,
  DELETE_TAGS,
  INSERT_ACCOMPANIMENTS,
  INSERT_MEALS_MUTATIONS,
  INSERT_PROCUDURES,
  INSERT_RECIPES,
  INSERT_SHELF_TIME,
  INSERT_TAGS,
  UPDATE_MEAL_BY_ID,
} from "../../../graphql/mutations";
import { GET_MEAL_DETAILS_BY_ID } from "../../../graphql/queries";
import { Enum_Droppables, Enum_Notification } from "../../../typescript/enum";
import {
  TODO,
  Type_Column,
  Type_MealInitialValues,
  Type_Notification,
  Type_SelectData,
  Type_SelectedIngredientObject,
} from "../../../typescript/types";
import { getNameFromAWSLink } from "../../../utils/getNameFromAWSLink";
import { replacePlusWithSpaces } from "../../../utils/replacePlusWithSpace";
import { replaceSpacesWithPlus } from "../../../utils/replaceSpaceWithPlus";
import { DeleteRowIcon } from "../../atoms/delete-row-icon/delete-row-icon";
import { FormLabel } from "../../atoms/form-label/form-label";
import { FormSubHeading } from "../../atoms/form-sub-heading/form-sub-heading";
import { OutlineButton } from "../../atoms/outline-button/outline-button";
import { Loader } from "../../molecules/loader/loader";
import { SelectedIngredients } from "../../molecules/selected-ingredient/selected-ingredient";
import { SelectedOptionRow } from "../../molecules/selected-option-row/selected-option-row";
import { UploadButton } from "../../molecules/upload-image-button/upload-image-button";
import { TagsList } from "../tags-list/tags-list";
import "./styles.css";
import { DragDropContext, DropResult, Droppable } from "react-beautiful-dnd";

export const OPTIONS = [
  {
    label: "Option 1",
    value: "option1",
  },
  {
    label: "Option 2",
    value: "option2",
  },
  {
    label: "Option 3",
    value: "option3",
  },
];

type AddMealFormProps = {
  cuisineType: Type_SelectData[];
  dishes: Type_SelectData[];
  ingredients: Type_SelectData[];
  units: Type_SelectData[];
  tags: Type_SelectData[];
  preparations: Type_SelectData[];
  selectedIngredientsObject: any;
  setSelectedIngredientsObject: React.Dispatch<any>;
  isUpdatePage: boolean;
  mealId: number | null;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  imageLabel: string;
  setImageLabel: React.Dispatch<React.SetStateAction<string>>;
  imageUrl: string;
  setImageUrl: React.Dispatch<React.SetStateAction<string>>;
  formValues: Type_MealInitialValues;
  setFormValues: React.Dispatch<React.SetStateAction<Type_MealInitialValues>>;
  setShowNotification: React.Dispatch<React.SetStateAction<boolean>>;
  setNotification: React.Dispatch<React.SetStateAction<Type_Notification>>;
  columns: Type_Column[];
  setColumns: React.Dispatch<React.SetStateAction<Type_Column[]>>;
};

type Type_IdsForDeletion = {
  procedure: number[];
  recipes: number[];
  accompaniments: number[];
  tags: number[];
  shelfTime: number;
  scalingUnit: number[];
};

export const AddMealForm: React.FC<AddMealFormProps> = ({
  cuisineType,
  dishes,
  ingredients,
  units,
  tags,
  preparations,
  selectedIngredientsObject,
  setSelectedIngredientsObject,
  isUpdatePage,
  mealId,
  loading,
  setLoading,
  imageUrl,
  setImageUrl,
  imageLabel,
  setImageLabel,
  formValues,
  setFormValues,
  setShowNotification,
  setNotification,
  columns,
  setColumns,
}) => {
  const history = useHistory();

  const [selectedCuisineType, setSelectedCuisineType] = useState<Type_SelectData>({
    label: "",
    value: "",
  });
  const [selectedAccompaniments, setSelectedAccompaniments] = useState<Type_SelectData[]>([]);
  const [procedure, setProcedure] = useState<string>("");
  const [selectedProcedures, setSelectedProcedures] = useState<string[]>([]);
  const [selectedIngredients, setSelectedIngredients] = useState<Type_SelectData[]>([]);
  const [ingredientFromUnits, setIngredientFromUnits] = useState<any[]>([]);
  const [ingredientToUnits, setIngredientToUnits] = useState<any[]>([]);
  const [ingredientPreparationTime, setIngredientPreparationTime] = useState<any[]>([]);
  const [selectedTags, setSelectedTags] = useState<Type_SelectData[]>([]);
  const [shelfTimeId, setShelfTimeId] = useState(0);
  const [idsForDeletion, setIdsForDeletion] = useState<Type_IdsForDeletion>({
    procedure: [],
    recipes: [],
    accompaniments: [],
    tags: [],
    shelfTime: 0,
    scalingUnit: [],
  });

  const [getMealDetails] = useLazyQuery(GET_MEAL_DETAILS_BY_ID, {
    fetchPolicy: "no-cache",
    variables: {
      where: {
        id: {
          _eq: mealId,
        },
      },
    },
    onCompleted: (data) => {
      setIdsForDeletion({
        accompaniments: [],
        procedure: [],
        recipes: [],
        scalingUnit: [],
        shelfTime: 0,
        tags: [],
      });
      const _meal = data.meal[0];
      setFormValues({
        name: _meal?.name,
        description: _meal?.description,
        prepTime: _meal?.prep_time && parseInt(_meal?.prep_time.split(" ")[0]),
        servingSize: parseFloat(_meal.serving),
        shelfLife: _meal?.shelf_time?.time && parseFloat(_meal?.shelf_time?.time.split(" ")[0]),
        videoURL: _meal?.video_url,
      });

      const _tags = _meal?.meal_filters?.map((filter: TODO) => {
        return { label: filter?.filter_tag?.tags, value: filter?.filter_tag?.id };
      });
      const _accompaniments = _meal.submeal_meals.map((accomp: TODO) => {
        return {
          value: accomp?.mealBySubMealId?.id,
          label: accomp?.mealBySubMealId?.name,
        };
      });
      const _ingredientsObject = _meal?.recipes.map((ingredients: TODO) => {
        return {
          id: ingredients?.ingredient?.id,
          scalingUnit: ingredients?.add_on_pax?.base_ratio_multiplier,
          ingredient: ingredients?.ingredient?.name,
          preparationTime: ingredients?.preparation,
          fromQuantity: ingredients?.quantity,
          unit: ingredients?.unit?.id,
        };
      });
      const _ingredientPreparationTime = _ingredientsObject.map((object: TODO) => {
        return {
          label: object?.preparationTime,
          value: object?.preparationTime,
        };
      });
      const _ingredientsUnits = _meal?.recipes.map((ingredients: TODO) => {
        return {
          label: ingredients?.unit?.name,
          value: ingredients?.unit?.id,
        };
      });
      const _ingredients = _ingredientsObject.map((object: TODO) => {
        return {
          label: object?.ingredient,
          value: object?.id,
        };
      });
      const _procedure = _meal?.instructions.map((instruction: TODO) => {
        return instruction?.name;
      });

      const _shelfLife = _meal?.shelf_time?.time && (_meal?.shelf_time?.time as string)?.split(" ");

      if (_meal?.image_url) {
        const _imageUrl = _meal?.image_url;
        const imageNameWithPluses: string = getNameFromAWSLink(_imageUrl);
        const imageNameWithoutPluses = replacePlusWithSpaces(imageNameWithPluses);
        setImageUrl(_imageUrl);
        setImageLabel(imageNameWithoutPluses);
      }

      //ids for removing & updating
      const recipeIds = _meal?.recipes.map((recipe: any) => recipe.id);
      const prodedureIds = _meal?.instructions.map((procedure: any) => procedure.id);
      const accompanimentIds = _meal.submeal_meals.map((accomp: TODO) => accomp?.id);
      const tagIds = _meal?.meal_filters?.map((filter: TODO) => filter.id);
      const scalingUnitIds = _meal?.recipes?.map((recipe: TODO) => recipe?.add_on_pax?.id);
      setIdsForDeletion({
        procedure: prodedureIds,
        recipes: recipeIds,
        accompaniments: accompanimentIds,
        tags: tagIds,
        shelfTime: _meal?.shelf_time?.time && parseFloat(_shelfLife[0]),
        scalingUnit: scalingUnitIds,
      });
      //------------>

      setSelectedCuisineType({ label: _meal.cuisine, value: _meal.cuisine });
      setSelectedTags(_tags);
      setSelectedAccompaniments(_accompaniments);
      setSelectedIngredientsObject(_ingredientsObject);
      setSelectedIngredients(_ingredients);

      // set procedures ----- Start
      const _columns = Array.from(columns);
      const _column = _columns[0];
      const newItems = _procedure;
      const newColumn = {
        ..._column,
        itemIds: newItems,
      };
      _columns.splice(0, 1);
      _columns.splice(0, 0, newColumn);
      setColumns(_columns);
      // set procedures ----- End

      setIngredientPreparationTime(_ingredientPreparationTime);
      setIngredientFromUnits(_ingredientsUnits);

      setLoading(false);
    },
  });

  useEffect(() => {
    if (isUpdatePage) {
      getMealDetails();
    }
  }, [mealId]);

  // Mutations
  const [deleteMutations] = useMutation(DELETE_MEALS_MUTATIONS, {
    onError: (error) => console.log(error),
  });
  const [insertMutations] = useMutation(INSERT_MEALS_MUTATIONS, {
    onError: (error) => console.log(error),
    onCompleted: async (data) => {
      if (data) {
        setShelfTimeId(data?.insert_shelf_time?.returning[0]?.id);
        const DataToUpdate = {
          name: formValues.name,
          description: formValues.description,
          cuisine: selectedCuisineType.label,
          prep_time: `${formValues.prepTime} mins`,
          serving: formValues.servingSize,
          image_url: imageUrl,
          video_url: formValues.videoURL,
          shelf_time_id: data?.insert_shelf_time?.returning[0]?.id,
        };
        await updateMeal({
          variables: {
            where: {
              id: {
                _eq: mealId,
              },
            },
            _set: DataToUpdate,
          },
        });
      }
    },
  });
  const [addMeal] = useMutation(ADD_NEW_MEAL, {
    onError: (error) => console.log(error),
  });
  const [updateMeal] = useMutation(UPDATE_MEAL_BY_ID, {
    onError: (error) => console.log(error),
  });
  // ------------->

  const handleFormValueChange: (event: React.ChangeEvent<HTMLInputElement & HTMLTextAreaElement>) => void = (e) =>
    setFormValues({ ...formValues, [e.target.name]: e.target.value });

  const onDeleteAccompaniments = (id: number) => {
    const filteredAccompaniments = selectedAccompaniments.filter((dish) => parseInt(dish.value) !== id);
    setSelectedAccompaniments(filteredAccompaniments);
  };

  const onDeleteTag = (id: number) => {
    const filterTags = selectedTags.filter((tag) => parseInt(tag.value) !== id);
    setSelectedTags(filterTags);
  };

  const onPressEnterInProcedure: (event: React.KeyboardEvent<HTMLInputElement>) => void = (e) => {
    if (e.key === "Enter" && procedure) {
      onAddStep();
    }
  };

  const onAddStep = () => {
    const column = columns[0];
    const newItems = [...column.itemIds, procedure];
    const newColumn = {
      ...column,
      itemIds: newItems,
    };
    setProcedure("");
    const _columns = Array.from(columns);
    _columns.splice(0, 1);
    _columns.splice(0, 0, newColumn);
    setColumns(_columns);
  };

  const onDeleteStep = (id: number) => {
    const _column = columns[0];
    const _columns = Array.from(columns);
    const newItems = _column.itemIds.filter((item, i) => i !== id);
    const newColumn = {
      ..._column,
      itemIds: newItems,
    };
    _columns.splice(0, 1);
    _columns.splice(0, 0, newColumn);
    setColumns(_columns);
  };

  const onDeleteIngredient = (id: number) => {
    const filteredIngredients = selectedIngredients.filter(
      (selectedIngredient) => parseInt(selectedIngredient.value) !== id,
    );
    const filteredIngredientsObject = selectedIngredientsObject.filter(
      (selectedIngredient: Type_SelectedIngredientObject) => selectedIngredient.id !== id,
    );
    setSelectedIngredients(filteredIngredients);
    setSelectedIngredientsObject(filteredIngredientsObject);
  };

  const onChangeIngredientsObject: (
    event: React.ChangeEvent<HTMLInputElement> | undefined,
    id: number,
    valueFromSelect?: Type_SelectData,
    action?: any,
  ) => void = (e, id, valueFromSelect, action) => {
    if (e && !action) {
      const _selectedIngredientsObject = selectedIngredientsObject.map((object: any) => {
        if (object.id === id) {
          return {
            ...object,
            [e.target.name]: e.target.value,
          };
        } else {
          return { ...object };
        }
      });
      setSelectedIngredientsObject(_selectedIngredientsObject);
    }
    if (action && valueFromSelect) {
      const _selectedIngredientsObject = selectedIngredientsObject.map((object: any) => {
        if (object.id === id) {
          return {
            ...object,
            [action.name]: valueFromSelect.value,
          };
        } else {
          return { ...object };
        }
      });
      setSelectedIngredientsObject(_selectedIngredientsObject);
    }
  };

  const onSaveMeal = () => {
    const DataToAdd = {
      name: formValues.name,
      description: formValues.description,
      cuisine: selectedCuisineType.label,
      image_url: imageUrl,
      submeal_meals: {
        data: selectedAccompaniments.map((dish) => {
          return {
            sub_meal_id: dish.value,
          };
        }),
      },
      meal_filters: {
        data: selectedTags.map((selectedTag) => {
          return {
            filter_tag_id: selectedTag.value,
          };
        }),
      },
      prep_time: `${formValues?.prepTime} mins`,
      serving: formValues.servingSize && formValues.servingSize,
      shelf_time: {
        data: {
          time: `${formValues.shelfLife} days`,
        },
      },
      video_url: formValues.videoURL,
      recipes: {
        data: selectedIngredientsObject.map((object: Type_SelectedIngredientObject) => {
          return {
            ingredients_id: object.id,
            quantity: object.fromQuantity,
            preparation: object.preparationTime,
            unit_id: object.fromUnit,
            add_on_pax: {
              data: {
                base_ratio_multiplier: object.scalingUnit,
              },
            },
          };
        }),
      },
      instructions: {
        data: columns[0].itemIds.map((procedure) => {
          return {
            name: procedure,
            description: procedure,
          };
        }),
      },
    };
    addMeal({
      variables: {
        objects: DataToAdd,
      },
    }).then(() => {
      setLoading(false);
      history.push(`/meals`);
    });
  };

  const onUpdateMeal = async () => {
    //values cannot be updated normally
    //first the values need to be deleted from table
    //then inserted again by providing the meal id

    try {
      await deleteMutations({
        variables: {
          instructions: {
            id: {
              _in: idsForDeletion.procedure,
            },
          },
          recipe: {
            id: {
              _in: idsForDeletion.recipes,
            },
          },
          addOnPax: {
            id: {
              _in: idsForDeletion.scalingUnit,
            },
          },
          submeal_meal: {
            id: {
              _in: idsForDeletion.accompaniments,
            },
          },
          mealFilters: {
            id: {
              _in: idsForDeletion.tags,
            },
          },
          shelfTime: {
            id: {
              _eq: idsForDeletion.shelfTime,
            },
          },
        },
      });

      const data: any = await insertMutations({
        variables: {
          instructions: columns[0].itemIds.map((procedure) => {
            return {
              name: procedure,
              description: procedure,
              meal_id: mealId,
            };
          }),
          recipe: selectedIngredientsObject.map((object: Type_SelectedIngredientObject) => {
            return {
              ingredients_id: object.id,
              quantity: object.fromQuantity,
              preparation: object.preparationTime,
              unit_id: object.unit,
              meal_id: mealId,
              add_on_pax: {
                data: {
                  base_ratio_multiplier: object.scalingUnit,
                },
              },
            };
          }),
          submeal_meal: selectedAccompaniments.map((dish) => {
            return {
              sub_meal_id: dish.value,
              meal_id: mealId,
            };
          }),
          mealFilters: selectedTags.map((selectedTag) => {
            return {
              filter_tag_id: selectedTag.value,
              meal_id: mealId,
            };
          }),
          shelfTime: {
            time: `${formValues.shelfLife} days`,
          },
        },
      });

      await getMealDetails();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = () => {
    if (isUpdatePage) {
      onUpdateMeal();
    } else {
      onSaveMeal();
    }
  };

  const handleImageUpload: (event: React.ChangeEvent<HTMLInputElement>) => void = async (e) => {
    setShowNotification(true);
    setNotification({
      heading: "File is uploading",
      type: Enum_Notification.warning,
    });
    setImageLabel("");
    setImageUrl("");
    try {
      if (e.target.files) {
        const file = e.target.files[0];
        let data = new FormData();
        data.append("files", file);
        const response = await fetch(FileUploadUrl, {
          method: "POST",
          body: data,
        });
        const _response = await response.json();
        setImageLabel(_response.filesUrl[0].originalname);
        const imageUrl = `${BASE_URL_S3_BUCKET_RESPONSE}/${replaceSpacesWithPlus(_response.filesUrl[0].originalname)}`;
        setImageUrl(imageUrl);
        setShowNotification(true);
        setNotification({
          heading: "File uploaded successfully",
          type: Enum_Notification.success,
        });
      }
    } catch (error) {
      console.log(error);
      setShowNotification(true);
      setNotification({
        heading: "File was not uploaded!",
        type: Enum_Notification.error,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveImage = () => {
    setImageLabel("");
    setImageUrl("");
  };

  // console.log({ columns });

  const onDragEnd = (result: DropResult) => {
    const { draggableId, source, destination } = result;
    // console.log({ draggableId, source, destination });

    if (!destination) return;

    if (destination.droppableId === source.droppableId && destination.index === source.index) return;

    let column;
    if (source.droppableId === Enum_Droppables.Procedure) column = columns[0];
    else if (source.droppableId === Enum_Droppables.Recipe) column = columns[1];

    // console.log({ column });
    if (column) {
      const _itemIds = Array.from(column.itemIds);
      // console.log({ _itemIds });
      _itemIds.splice(source.index, 1);
      _itemIds.splice(destination.index, 0, column.itemIds[parseInt(draggableId)]);
      const newColumn = {
        ...column,
        itemIds: _itemIds,
      };
      const _columns = Array.from(columns);
      // console.log({ newColumn, _columns });
      _columns[source.droppableId === Enum_Droppables.Procedure ? 0 : 1] = newColumn;
      setColumns(_columns);
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {!loading ? (
        <div className="flex flex-col">
          <div className="w-[568px]">
            <FormLabel label="Dish Name*" />
            <input
              className="input"
              type="text"
              placeholder="Dish Name*"
              value={formValues.name}
              name="name"
              onChange={handleFormValueChange}
            />

            <FormLabel label="Upload Image" />
            <div className="flex items-center gap-x-[20px]">
              <UploadButton label={imageLabel || "Select Image"} handleImageUpload={handleImageUpload} />
              <div className="relative">
                {imageUrl && <img src={imageUrl} alt="" className="w-[120px] h-[100px] rounded-md" />}
                <div className="absolute top-[5px] right-[5px]" onClick={handleRemoveImage}>
                  <DeleteRowIcon />
                </div>
              </div>
            </div>

            <FormLabel label="Description*" />
            <textarea
              className="input"
              style={{ height: 151 }}
              placeholder="Description*"
              value={formValues.description}
              name="description"
              onChange={handleFormValueChange}
            />

            <FormLabel label="Cuisine Type*" />
            <Select
              options={cuisineType}
              isSearchable
              value={selectedCuisineType}
              onChange={(value: any) => setSelectedCuisineType(value)}
              className="mb-[30px]"
              styles={STYLES}
            />

            {/* <FormLabel label="Compatible Diet*" />
        <MultiSelect
          options={OPTIONS}
          labelledBy="Compatible Diet*"
          value={tempSelectValue}
          onChange={setTempSelectValue}
          disableSearch
          className="select mb-[30px]"
        /> */}

            {/* <FormLabel label="Allergens*" />
        <Select
          options={allergies}
          value={selectedAllergens}
          isSearchable
          isMulti
          onChange={(value: any) => setSelectedAllergens(value)}
          className=" mb-[30px]"
          styles={STYLES}
        /> */}

            <FormLabel label="Tags" />
            <TagsList tags={selectedTags} onDeleteTag={onDeleteTag} />
            <Select
              options={tags}
              value={selectedTags}
              isSearchable
              isMulti
              onChange={(value: any) => setSelectedTags(value)}
              className="mb-[30px]"
              styles={STYLES}
            />

            <FormLabel label="Accompaniments*" />
            <Select
              options={dishes}
              value={selectedAccompaniments}
              onChange={(value: any) => setSelectedAccompaniments(value)}
              className="mb-[30px]"
              isMulti
              styles={STYLES}
            />

            <div>
              {selectedAccompaniments.map((dish) => (
                <SelectedOptionRow
                  label={dish.label}
                  key={dish.value}
                  id={parseInt(dish.value)}
                  onDeleteRow={onDeleteAccompaniments}
                />
              ))}
            </div>
          </div>

          <div className="mt-[90px] mb-[42px]">
            <FormSubHeading heading="Define Recipe Details" />
          </div>

          <div className="flex items-center gap-x-[40px] w-[860px]">
            <div>
              <FormLabel label="Serving Size" />
              <input
                type="number"
                className="w-[138px] input"
                value={formValues.servingSize}
                name="servingSize"
                onChange={handleFormValueChange}
                min={0}
              />
            </div>
            <div>
              <FormLabel label="Prep Time (mins)" />
              <input
                type="number"
                className="w-[138px] input"
                value={formValues.prepTime}
                name="prepTime"
                onChange={handleFormValueChange}
                min={0}
              />
            </div>
            <div>
              <FormLabel label="Shelf Life (days)" />
              <input
                type="number"
                className="w-[138px] input"
                value={formValues.shelfLife}
                name="shelfLife"
                onChange={handleFormValueChange}
                min={0}
              />
            </div>
            <div>
              <FormLabel label="Video URL" />
              <input
                type="text"
                className=" input"
                style={{ width: 328 }}
                value={formValues.videoURL}
                name="videoURL"
                onChange={handleFormValueChange}
              />
            </div>
          </div>

          <div className="w-[860px]">
            <FormLabel label="Ingredients" />
            <Select
              options={ingredients}
              name="Ingredients"
              value={selectedIngredients}
              onChange={(value: any) => {
                setSelectedIngredients(value);
                //get last value from values array
                //add it to end of selectedIngredientsObject
                const objectToAdd = {
                  id: value[value.length - 1].value,
                  ingredient: value[value.length - 1].label,
                  preparationTime: "",
                  unit: null,
                  // fromQuantity: null,
                  // fromUnit: null,
                  // toQuantity: null,
                  // toUnit: null,
                };
                setSelectedIngredientsObject([...selectedIngredientsObject, objectToAdd]);
              }}
              className="mb-[30px]"
              isMulti
              styles={STYLES}
            />
          </div>

          <div>
            {selectedIngredients.length > 0 && (
              <SelectedIngredients
                {...{
                  selectedIngredients,
                  onChangeIngredientsObject,
                  onDeleteIngredient,
                  selectedIngredientsObject,
                  preparations,
                  ingredientFromUnits,
                  setIngredientFromUnits,
                  ingredientPreparationTime,
                  setIngredientPreparationTime,
                  ingredientToUnits,
                  setIngredientToUnits,
                }}
                units={units}
              />
            )}
          </div>

          <FormLabel label="Procedure" />
          <div className="flex items-center gap-x-[18px] w-[860px]">
            <input
              type="text input"
              className="flex-1  mb-0 input"
              style={{ marginBottom: 0 }}
              value={procedure}
              onChange={(e) => {
                if (e.target.value) setProcedure(e.target.value);
              }}
              onKeyPress={(e) => onPressEnterInProcedure(e)}
            />
            <div onClick={onAddStep}>
              <OutlineButton label="Add step" />
            </div>
          </div>
          <Droppable droppableId={columns[0].id}>
            {(provided) => (
              <div className="w-[860px] mt-[28px]" {...provided.droppableProps} ref={provided.innerRef}>
                {columns[0].itemIds.map((procedure, index) => (
                  <SelectedOptionRow
                    renderBurgerIcon
                    label={procedure}
                    id={index}
                    onDeleteRow={onDeleteStep}
                    key={index}
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>

          <div className="flex items-center gap-x-[30px] mt-[51px]">
            <button
              className="submit-button"
              onClick={(e) => {
                setLoading(true);
                onSubmit();
              }}
              disabled={loading}
            >
              {isUpdatePage ? "Update Meal" : "Save Meal"}
            </button>
            <p
              onClick={() => history.goBack()}
              className="flex items-center text-gray3 text-[12px] leading-[15px] font-medium mt-[7px] cursor-pointer hover:text-primary"
            >
              Cancel
            </p>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </DragDropContext>
  );
};
