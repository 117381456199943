import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Logo } from "../../atoms/logo/logo";
import { SidebarLabel } from "../../atoms/sidebar-label/sidebar-label";

enum Enum_Sidebar {
  Home = "home",
  Ingredients = "ingredients",
  CreateIngredients = "create-new-ingredient",
  UpdateIngredients = "update-ingredient",
  Dishes = "meals",
  CreateDishes = "create-new-meal",
  UpdateDished = "update-meal",
  Settings = "settings",
}

export const Sidebar: React.FC = () => {
  const location = useLocation();
  const [presentLocation, setPresentLocation] = useState("");

  const isOnIngredient: () => boolean = () => {
    const { CreateIngredients, Ingredients, UpdateIngredients } = Enum_Sidebar;
    if (
      presentLocation === CreateIngredients ||
      presentLocation === Ingredients ||
      presentLocation === UpdateIngredients
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isOnMeals: () => boolean = () => {
    const { CreateDishes, Dishes, UpdateDished } = Enum_Sidebar;
    if (presentLocation === CreateDishes || presentLocation === Dishes || presentLocation === UpdateDished) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (location.pathname) {
      const _presentLocation = location.pathname.split("/")[1];
      _presentLocation ? setPresentLocation(_presentLocation) : setPresentLocation("home");
    }
  }, [location.pathname]);
  return (
    <div className="flex flex-col items-center w-[150px] min-h-screen bg-primary text-white fixed left-0 top-0 pt-[24px]">
      <Logo />
      <SidebarLabel label="Home" selected={presentLocation === Enum_Sidebar.Home ? true : false} to="/" />
      <SidebarLabel label="Ingredients" selected={isOnIngredient()} to="/ingredients" />
      <SidebarLabel label="Dishes" selected={isOnMeals()} to="/meals" />
      <SidebarLabel
        label="Settings"
        selected={presentLocation === Enum_Sidebar.Settings ? true : false}
        to="/settings"
      />
    </div>
  );
};
