import React from "react";
import { BurgerIcon } from "../../atoms/burger-icon/burger-icon";
import { DeleteRowIcon } from "../../atoms/delete-row-icon/delete-row-icon";
import { Draggable } from "react-beautiful-dnd";
type SelectedOptionRowProps = {
  renderBurgerIcon?: boolean;
  label: string;
  id: number;
  onDeleteRow: (id: number) => void;
};

export const SelectedOptionRow: React.FC<SelectedOptionRowProps> = ({
  renderBurgerIcon = false,
  label,
  id,
  onDeleteRow,
}) => {
  return (
    <>
      {renderBurgerIcon ? (
        <Draggable draggableId={id.toString()} index={id}>
          {(provided) => (
            <div
              className="flex justify-between items-center py-[15px] border-t border-b border-[#EAE9E9] bg-white"
              {...provided.draggableProps}
              ref={provided.innerRef}
            >
              {renderBurgerIcon && (
                <div className="mr-[24px] pl-[6px]" {...provided.dragHandleProps}>
                  <BurgerIcon />
                </div>
              )}
              <p className="flex-1 text-[13px] leading-[20px] text-fontGray">{label}</p>
              <div onClick={() => onDeleteRow(id)}>
                <DeleteRowIcon />
              </div>
            </div>
          )}
        </Draggable>
      ) : (
        <div className="flex justify-between items-center py-[15px] border-t border-b border-[#EAE9E9] bg-white">
          {renderBurgerIcon && (
            <div className="mr-[24px] pl-[6px]">
              <BurgerIcon />
            </div>
          )}
          <p className="flex-1 text-[13px] leading-[20px] text-fontGray">{label}</p>
          <div onClick={() => onDeleteRow(id)}>
            <DeleteRowIcon />
          </div>
        </div>
      )}
    </>
  );
};
