export const Enum_Time = {
  Days: "days"
}

export enum Enum_Notification {
  success = 'success',
  warning = 'warning',
  error = 'error',
  neutral = 'neutral',
}

export enum Enum_Droppables {
  Procedure = "procedure",
  Recipe = "recipe",
}