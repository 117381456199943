import React from "react";
import { useHistory } from "react-router-dom";

type SidebarLabelProps = {
  label: string;
  selected: boolean;
  to: string;
};

export const SidebarLabel: React.FC<SidebarLabelProps> = ({ label, selected, to }) => {
  const history = useHistory();
  return (
    <div
      onClick={() => history.push(to)}
      className={`cursor-pointer text-[14px] leading-[18px] font-semibold text-center p-[22px] w-full ${
        selected ? "text-primary bg-white" : "bg-primary text-white"
      }`}
    >
      {label}
    </div>
  );
};
