import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Router from "./router/router";
import { routes } from "./router/routeConfig";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { Headers, Uri } from "./config/config";

const client = new ApolloClient({
  uri: Uri,
  cache: new InMemoryCache(),
  headers: Headers,
  
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Router routes={routes} />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);
