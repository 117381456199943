import React from "react";
import { Types_Ingredients } from "../../../typescript/types";
import { SingleIngredientControls } from "../../molecules/single-ingredient-controls/single-ingredient-controls";
import InfiniteScroll from "react-infinite-scroll-component";
import { Loader } from "../../atoms/loader/loader";
type ListProps = {
  data: Types_Ingredients[];
  totalIngredients: number;
  handleEdit: (id: number) => void;
  handleDelete: (id: number) => void;
  pagination: () => void;
  justSearchedFlag: boolean
};

export const IngredientsList: React.FC<ListProps> = ({
  data,
  totalIngredients,
  handleEdit,
  handleDelete,
  pagination,
  justSearchedFlag
}) => {
  return (
    <InfiniteScroll
      dataLength={data.length}
      hasMore={!justSearchedFlag && data.length < totalIngredients}
      loader={<Loader />}
      next={() => pagination()}
    >
      <table className="w-full">
        <thead>
          <tr className="text-gray3 text-left border-b border-[#eae9e9] text-[12px] leading-[18px] ">
            <th className="w-[400px] py-[21px] px-[15px]">Name</th>
            <th className="w-[230px]">Brand</th>
            <th className="w-[230px]">Allergens</th>
            <th className="w-[230px]">Usage</th>
            <th className="w-[230px]"></th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => {
            return (
              <tr className="text-[12px] leading-[18px]  text-gray3 font-normal border-b border-[#eae9e9] hover:bg-[#F8F8F8] cursor-pointer" key={item.id}>
                <td className="w-[400px] text-primary py-[21px] font-semibold px-[15px] capitalize">{item.name}</td>
                <td className="w-[230px]"></td>
                <td className="w-[230px]"></td>
                <td className="w-[230px]"></td>
                <td className="w-[230px]">
                  <SingleIngredientControls {...{ handleEdit, handleDelete }} id={item.id} />{" "}
                </td>
              </tr>
            );
          })}

          {/* <td className="w-[230px]">Masterbrand</td>
            <td className="w-[230px]">Gluten</td>
            <td className="w-[230px]">3</td> */}
        </tbody>
      </table>
    </InfiniteScroll>
  );
};
